
body {
    margin: 0px;
}
/* Define the CSS for the parent container */
.parent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3px auto;
    width: 90vmin; /* Set the max width to the viewport width */
    height: 90vmin; /* Set the height equal to the viewport width */
    overflow:hidden; /* Enable scrolling if content overflows */
    /* overflow:hidden;
    /* width:100%; */
    /* height:100%; */ 
}

.parent-container-start {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    /* width: 90vmin; /* Set the max width to the viewport width */
    /*height: 90vmin; /* Set the height equal to the viewport width */
    /*overflow:hidden; Enable scrolling if content overflows */
    overflow:hidden;
    width:90vmin;
    height:100%; 

}

/* Define the CSS for the flex container */
.flex-container {
    display: flex;
    flex-direction: row; /* Stack flex items horizontally */
    flex: 1 1; /* Allow flex containers to grow and take up available space */
    min-width: 100%;
}

/* Style for the flex items */
.flex-item {
    background-color: green; /* Set the background color to green */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 2px solid white; /* Add a white border */
    flex: 1; /* Allow flex items to take up maximum available width */
    /* min-width: 33.3%; */
    position: relative;
    overflow: hidden; /* Hide the button initially */
}

/* Style for the level container */
.level-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    /* width: 50%;
    height: 50%; */
}

/* Style for the Level 1 heading */
h1 {
    font-size: 24px;
}

/* Style for the flex container holding green circles */
.circle-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex:1 1;
    width: 170px;
    height: 50px;
}

/* Style for the green circles */
.circle {
    width: 40px;
    height: 40px;
    background-color: white;
    border: 2px solid green;
    border-radius: 50%;
    aspect-ratio: 1/1;
}

button.game {
    width: 70px;
    height: 30px;
}




button.rodent {
    width: 82%; /* Button width is 70% of flex-item */
    height: 80%; /* Button height is 80% of flex-item */
    border:none;
    background-image: url('../assets/rodent.png'); /* Set the background image */
    background-size: cover; /* Scale the image to cover the entire button */

    position: absolute;
    top: 100%; /* Offset the button below the flex-item */
    left: 50%; /* Center horizontally to the flex-item */
    transform: translateX(-50%); /* Center horizontally to the div */
    transition: top 1s ease-in-out; /* Add animation transition */
}